import React from 'react'
  /* @jsx mdx */
import { mdx } from '@mdx-js/react';
/* @jsx mdx */

import DefaultLayout from "/vercel/workpath0/packages/swarm-docs/src/components/layout-mdx.js";
import { LinkButton } from '@meetup/swarm-components';
import { Icon } from '@meetup/swarm-components';
import Example from '../../components/examples/linkButton';
import PropsTable from '../../components/propstable';
export const _frontmatter = {};

const makeShortcode = name => function MDXDefaultShortcode(props) {
  console.warn("Component " + name + " was not imported, exported, or provided by MDXProvider as global scope");
  return <div {...props} />;
};

const layoutProps = {
  _frontmatter
};
const MDXLayout = DefaultLayout;
export default function MDXContent({
  components,
  ...props
}) {
  return <MDXLayout {...layoutProps} {...props} components={components} mdxType="MDXLayout">



    <h1>{`LinkButton`}</h1>
    <hr></hr>
    <p>{`An html anchor tag styled as a button.`}</p>
    <br />
    <br />
    <h3>{`Snippet`}</h3>
    <hr></hr>
    <p>{`Use this interactive snippet to try the component:`}</p>
    <br />
    <pre><code parentName="pre" {...{
        "className": "language-.jsx"
      }}>{`<LinkButton href="https://www.meetup.com" primary>Link styled as Button</LinkButton>
`}</code></pre>
    <br />
    <br />
    <h3>{`Props`}</h3>
    <hr></hr>
    <br />
    <PropsTable propMetaData={LinkButton.__docgenInfo} mdxType="PropsTable" />
    <br />
    <br />
    <h3>{`a11y`}</h3>
    <hr></hr>
    <p>{`Keyboard interactions: The button action should be triggered by the enter key.`}</p>
    <br />
    <br />
    <h2>{`Examples`}</h2>
    <hr></hr>
    <Example mdxType="Example" />

    </MDXLayout>;
}
;
MDXContent.isMDXComponent = true;
      